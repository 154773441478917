import { template as template_6af907d1f19d47d680b2df4457e7724b } from "@ember/template-compiler";
const SidebarSectionMessage = template_6af907d1f19d47d680b2df4457e7724b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
