import { template as template_2557e5160a554207983e6b5c80cf6970 } from "@ember/template-compiler";
const FKLabel = template_2557e5160a554207983e6b5c80cf6970(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
