import { template as template_2b0b09791562421883aef1c6ece55e80 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2b0b09791562421883aef1c6ece55e80(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
